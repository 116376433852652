import React, {useState, useEffect} from 'react'
import './Reservations.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link, Redirect, withRouter} from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from 'axios';

// import slots from './slots'


// 5:00pm, 5:15pm




function Reservation ({history, match}) {
  const { register, handleSubmit, watch, errors } = useForm();
  const [errorMsg, setErrorMsg] = useState('')
  const [slots, setSlots]= useState([])
  const [appState, setAppState]= useState('SLOTS')
  const [selectedSlotId, setSelectedSlotId]= useState(false)
  const params = match.params
  // const history = useHistory()
  // const [email, setEmail] = useState('')
  // const [phone, setPhone] = useState('')
  // const [partySize, setPartySize] = useState('')
  // const [notes, setNotes] = useState('')

  function fetchReservations () {
    fetch('https://llama-secret-reserve.glitch.me/available')
      .then((res) => res.json())
      .then((json) => setSlots(json))
  }

  useEffect(fetchReservations, [0])


  const onSubmit = data => {
    console.log(data)
    console.log(selectedSlotId)
    const submission = Object.assign({}, data, {slotId: selectedSlotId})
    axios.post('https://llama-secret-reserve.glitch.me/reserve', submission)
      .then((response) => {
        console.log(response.data)
        const {success, message, name, hour, minute, day, available} = (response.data)
        if (!success) {
          fetchReservations()
          setErrorMsg(message)
        } else {
          // success
          history.push(`/reserve/${day}/${hour}/${minute}/${name}`)
        }

      })

  };

  if (params.name) {
    const {name, hour, minute, date} = params
    return(
      <div className="page">
        <div className="reservations-panel">
          <div className="confirmation">
            <h1>Reservation Confirmation</h1>
            
            <p>Thursday September 10</p>
            <p>{Number(hour) - 12}:{minute}pm</p>
            <p>{name}</p>
          </div>
        </div>
      </div>
    )
  }



  return(
    <div className="page">

      <div className="reservations-panel">
        <div className="header">
          <h1>Thursday September 10 Reservations</h1>
  
          <p>NOTE: Reservations will only be held for 15min</p>

          {/* <p>First come first serve seating also available. join our <Link to="/order">mailing list</Link> for future reservations</p> */}

        </div>
        
        {(true) && 
          <form 
            onSubmit={handleSubmit(onSubmit)} 
            className="info">
            <p>
              <label><span className="text">Name: </span><input   
                  type="text" 
                  name="name"
                  ref={register}
                />
              </label>
            </p> 
            <p>
              <label><span className="text">Email: </span><input   
                  type="email" 
                  name="email"
                  ref={register}
                />
              </label>
            </p> 
            <p>
              <label><span className="text">Phone: </span><input   
                  type="text" 
                  name="phone"
                  ref={register}
                />
              </label>
            </p> 
            <p>
              <label><span className="text">Party Size: </span><input   
                  type="text" 
                  name="partySize"
                  ref={register}
                />
              </label>
            </p> 


            {(appState === 'SLOTS') && <div className="slots">
              {slots.map(({id: slotId, day, hour, minute, reserved, available}) => {
                const display = `${hour - 12}:${minute}pm`
                const selected = (selectedSlotId === slotId)
                let className = selected ? "selected slot" : "slot"
                if (reserved) {className = 'reserved slot'}
                return (
                  <div className="slot-wrapper">
                    <div key={slotId} className={className}>
                      <input 
                        name="slotId"
                        value={slotId}
                        id={slotId} 
                        checked={selected}
                        type="radio"
                        disabled={reserved}
                        onChange={(event) => setSelectedSlotId(event.target.checked ? slotId : false)}
                      />
                      <label
                        htmlFor={slotId}
                      >
                        <div className="time">{display}</div>
                        {!reserved && <div className="available">{available} spots</div>}
                      
                      </label>   
                    </div>
                  </div>
                )
                })
              }
            </div>}

            

            <div className="submit-wrapper">
            {errorMsg && 
              <div className="error-message">
                {errorMsg}
              </div>
            }
            	<input 
            	  className="submit-button"
            	  value="reserve"
            	  type="submit"/>
            </div>

            
          </form>
        } 




      </div>

      
    </div>
  )
}

export default withRouter(Reservation)